import React from 'react';
import { ROICalculation } from '../types';

interface Props {
  results: ROICalculation;
}

export default function ResultsDisplay({ results }: Props) {
  const formatCurrency = (value: number) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(value);
  };

  const ResultCard = ({ title, value, description }: { title: string; value: string; description: string }) => (
    <div className="bg-gray-50 rounded-lg p-4">
      <h3 className="text-sm font-medium text-gray-500">{title}</h3>
      <p className="mt-1 text-2xl font-semibold text-gray-900">{value}</p>
      <p className="mt-1 text-sm text-gray-600">{description}</p>
    </div>
  );

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
      <ResultCard
        title="Monthly Revenue Increase"
        value={formatCurrency(results.projectedRevenue - results.currentRevenue)}
        description="Additional monthly revenue from SEO improvements"
      />
      <ResultCard
        title="Monthly Profit"
        value={formatCurrency(results.monthlyProfit)}
        description="Net profit after subtracting monthly costs"
      />
      <ResultCard
        title="Annual ROI"
        value={`${results.roi.toFixed(1)}%`}
        description="Return on investment over one year"
      />
      <ResultCard
        title="Months to Break Even"
        value={results.monthsToBreakeven.toFixed(1)}
        description="Time to recover initial investment"
      />
    </div>
  );
}