import React from 'react';
import { HelpCircle } from 'lucide-react';
import { SEOMetrics } from '../types';

interface Props {
  initialMetrics: SEOMetrics;
  onCalculate: (metrics: SEOMetrics) => void;
  onChange: (metrics: SEOMetrics) => void;
}

export default function MetricsForm({ initialMetrics, onCalculate, onChange }: Props) {
  const [metrics, setMetrics] = React.useState<SEOMetrics>(initialMetrics);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const newMetrics = { ...metrics, [name]: parseFloat(value) || 0 };
    setMetrics(newMetrics);
    onChange(newMetrics);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onCalculate(metrics);
  };

  const Tooltip = ({ text }: { text: string }) => (
    <div className="group relative inline-block ml-2">
      <HelpCircle className="h-4 w-4 text-gray-400 hover:text-gray-600 cursor-help" />
      <div className="hidden group-hover:block absolute z-10 w-64 p-2 mt-2 text-sm text-white bg-gray-900 rounded-lg shadow-lg -left-1/2 transform -translate-x-1/2">
        {text}
      </div>
    </div>
  );

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      <div className="space-y-4">
        <h2 className="text-xl font-semibold text-gray-900 mb-4">Current Metrics</h2>
        
        <div>
          <label className="flex items-center text-sm font-medium text-gray-700">
            Monthly Traffic
            <Tooltip text="The average number of visitors to your website per month" />
          </label>
          <input
            type="number"
            name="monthlyTraffic"
            value={metrics.monthlyTraffic}
            onChange={handleChange}
            min="0"
            required
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          />
        </div>

        <div>
          <label className="flex items-center text-sm font-medium text-gray-700">
            Conversion Rate (%)
            <Tooltip text="The percentage of visitors who complete a desired action" />
          </label>
          <input
            type="number"
            name="conversionRate"
            value={metrics.conversionRate}
            onChange={handleChange}
            min="0"
            max="100"
            step="0.1"
            required
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          />
        </div>

        <div>
          <label className="flex items-center text-sm font-medium text-gray-700">
            Average Order Value ($)
            <Tooltip text="The average amount spent per conversion" />
          </label>
          <input
            type="number"
            name="averageOrderValue"
            value={metrics.averageOrderValue}
            onChange={handleChange}
            min="0"
            required
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          />
        </div>

        <h2 className="text-xl font-semibold text-gray-900 mt-8 mb-4">Projected Improvements</h2>

        <div>
          <label className="flex items-center text-sm font-medium text-gray-700">
            Traffic Increase (%)
            <Tooltip text="Expected percentage increase in monthly traffic" />
          </label>
          <input
            type="number"
            name="trafficIncrease"
            value={metrics.trafficIncrease}
            onChange={handleChange}
            min="0"
            required
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          />
        </div>

        <div>
          <label className="flex items-center text-sm font-medium text-gray-700">
            Conversion Rate Boost (%)
            <Tooltip text="Expected percentage improvement in conversion rate" />
          </label>
          <input
            type="number"
            name="conversionBoost"
            value={metrics.conversionBoost}
            onChange={handleChange}
            min="0"
            required
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          />
        </div>

        <h2 className="text-xl font-semibold text-gray-900 mt-8 mb-4">Investment</h2>

        <div>
          <label className="flex items-center text-sm font-medium text-gray-700">
            Monthly SEO Investment ($)
            <Tooltip text="Monthly cost of SEO services and maintenance" />
          </label>
          <input
            type="number"
            name="monthlyInvestment"
            value={metrics.monthlyInvestment}
            onChange={handleChange}
            min="0"
            required
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          />
        </div>

        <div>
          <label className="flex items-center text-sm font-medium text-gray-700">
            Implementation Cost ($)
            <Tooltip text="One-time setup and implementation costs" />
          </label>
          <input
            type="number"
            name="implementationCost"
            value={metrics.implementationCost}
            onChange={handleChange}
            min="0"
            required
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          />
        </div>
      </div>

      <div>
        <button
          type="submit"
          className="w-full flex justify-center py-3 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Calculate ROI
        </button>
      </div>
    </form>
  );
}