import React, { useState } from 'react';
import { Calculator, HelpCircle, Download, TrendingUp } from 'lucide-react';
import { SEOMetrics, ROICalculation } from '../types';
import MetricsForm from './MetricsForm';
import ResultsDisplay from './ResultsDisplay';
import ROIChart from './ROIChart';

const initialMetrics: SEOMetrics = {
  monthlyTraffic: 10000,
  conversionRate: 2,
  averageOrderValue: 100,
  trafficIncrease: 50,
  conversionBoost: 20,
  monthlyInvestment: 2000,
  implementationCost: 5000,
};

export default function SEOCalculator() {
  const [metrics, setMetrics] = useState<SEOMetrics>(initialMetrics);
  const [results, setResults] = useState<ROICalculation | null>(null);

  const calculateROI = (metrics: SEOMetrics): ROICalculation => {
    const currentMonthlyConversions = (metrics.monthlyTraffic * metrics.conversionRate) / 100;
    const currentRevenue = currentMonthlyConversions * metrics.averageOrderValue;

    const projectedTraffic = metrics.monthlyTraffic * (1 + metrics.trafficIncrease / 100);
    const projectedConversionRate = metrics.conversionRate * (1 + metrics.conversionBoost / 100);
    const projectedMonthlyConversions = (projectedTraffic * projectedConversionRate) / 100;
    const projectedRevenue = projectedMonthlyConversions * metrics.averageOrderValue;

    const monthlyProfit = projectedRevenue - currentRevenue - metrics.monthlyInvestment;
    const annualProfit = monthlyProfit * 12 - metrics.implementationCost;
    const totalInvestment = metrics.monthlyInvestment * 12 + metrics.implementationCost;
    const roi = (annualProfit / totalInvestment) * 100;
    const monthsToBreakeven = metrics.implementationCost / monthlyProfit;

    return {
      currentRevenue,
      projectedRevenue,
      monthlyProfit,
      annualProfit,
      roi,
      monthsToBreakeven,
    };
  };

  const handleCalculate = (metrics: SEOMetrics) => {
    const results = calculateROI(metrics);
    setResults(results);
  };

  const handleExport = () => {
    if (!results) return;
    
    const data = {
      metrics,
      results,
      date: new Date().toISOString(),
    };

    const blob = new Blob([JSON.stringify(data, null, 2)], { type: 'application/json' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'seo-roi-calculation.json';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-50 to-indigo-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        <div className="text-center mb-12">
          <div className="flex items-center justify-center mb-4">
            <TrendingUp className="h-12 w-12 text-indigo-600" aria-hidden="true" />
          </div>
          <h1 className="text-4xl font-bold text-gray-900 mb-4">
            SEO ROI Calculator
          </h1>
          <p className="text-lg text-gray-600 max-w-2xl mx-auto">
            Calculate the potential return on investment for your SEO efforts with our advanced calculator. Make data-driven decisions for your digital marketing strategy.
          </p>
          <div className="mt-4 text-sm text-gray-500">
            <p>✓ Free to use ✓ Instant results ✓ No registration required</p>
          </div>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
          <div className="bg-white rounded-xl shadow-lg p-6">
            <MetricsForm
              initialMetrics={metrics}
              onCalculate={handleCalculate}
              onChange={setMetrics}
            />
          </div>

          {results && (
            <div className="bg-white rounded-xl shadow-lg p-6">
              <div className="flex justify-between items-center mb-6">
                <h2 className="text-2xl font-semibold text-gray-900">Results</h2>
                <button
                  onClick={handleExport}
                  className="flex items-center px-4 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 transition-colors"
                  aria-label="Export results"
                >
                  <Download className="h-4 w-4 mr-2" aria-hidden="true" />
                  Export
                </button>
              </div>
              <ResultsDisplay results={results} />
              <div className="mt-6">
                <ROIChart currentRevenue={results.currentRevenue} projectedRevenue={results.projectedRevenue} />
              </div>
            </div>
          )}
        </div>

        <footer className="mt-16 text-center text-sm text-gray-500">
          <p className="mb-2">
            This calculator provides estimates based on your inputs. Actual results may vary depending on various factors.
          </p>
          <p>
            Learn more about{' '}
            <a href="#" className="text-indigo-600 hover:text-indigo-700">
              how we calculate ROI
            </a>
            {' '}and{' '}
            <a href="#" className="text-indigo-600 hover:text-indigo-700">
              SEO best practices
            </a>
          </p>
        </footer>
      </div>
    </div>
  );
}