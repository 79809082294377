import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

interface Props {
  currentRevenue: number;
  projectedRevenue: number;
}

export default function ROIChart({ currentRevenue, projectedRevenue }: Props) {
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const,
      },
      title: {
        display: true,
        text: 'Revenue Comparison',
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          callback: (value: number) => {
            return new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: 'USD',
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            }).format(value);
          },
        },
      },
    },
  };

  const data = {
    labels: ['Monthly Revenue'],
    datasets: [
      {
        label: 'Current Revenue',
        data: [currentRevenue],
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
      },
      {
        label: 'Projected Revenue',
        data: [projectedRevenue],
        backgroundColor: 'rgba(75, 192, 192, 0.5)',
      },
    ],
  };

  return <Bar options={options} data={data} />;
}